import React, { useEffect, useState } from "react";
import { Modal } from "../../common/Modal/Modal";
import css from "./style.module.scss";
import classNames from "classnames";

const SHUFFLE_INTERVAL = 20000;
const FADE_DURATION = 500;

export const CentralText = ({ collagen }: { collagen: string[] }) => {
  const [currentCollage, setCurrentCollage] = useState(0);
  const [startFade, setStartFade] = useState(false);

  useEffect(() => {
    let timeoutFadeaway: NodeJS.Timeout;
    let timeoutFadein: NodeJS.Timeout;
    let interval: NodeJS.Timeout;

    if (collagen.length > 1) {
      interval = setInterval(() => {
        setStartFade(true);
        timeoutFadeaway = setTimeout(() => {
          setCurrentCollage((s) => (s === collagen.length - 1 ? 0 : s + 1));
          timeoutFadein = setTimeout(() => {
            setStartFade(false);
          }, FADE_DURATION);
        }, FADE_DURATION);
      }, SHUFFLE_INTERVAL - FADE_DURATION);
    }
    return () => {
      clearInterval(interval);
      clearTimeout(timeoutFadeaway);
      clearTimeout(timeoutFadein);
    };
  }, [collagen.length]);

  const [modalOpened, setModalOpened] = useState(false);

  return (
    <div className={css["container"]}>
      <div style={{ position: "relative" }}>
        <div className={css["people-images"]}>
          {/* <Image
            className={css["people"]}
            height={240}
            layout="fixed"
            src={require("../../../public/images/collage-kita-hub-bayern-002.png")}
            alt="Drei Kita Fachkräfte stehen nebeneinander. Links eine Frau Mitte 20 mit einem Tablet in der Hand, neben ihr ein Mann Mitte 30, der freundlich und aufgeschlossen lacht. Neben ihm eine Fachkraft Anfang 60. Alle drei haben ihren Platz auf dem KITA HUB gefunden und können die Vorteile nutzen. Sie sind miteinder digtial verbunden durch die Dienste und Angebote des KITA HUB."
          /> */}
          {/* <img
            className={css["logo"]}
            src="/images/Logo_HeroBox_BG.svg"
            alt=""
          /> */}

          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className={classNames(css["people"], {
              [css["fade-away"]]: startFade,
            })}
            src={`/images/collagen/${collagen[currentCollage]}`}
            alt="Drei Kita Fachkräfte stehen nebeneinander. Links eine Frau Mitte 20 mit einem Tablet in der Hand, neben ihr ein Mann Mitte 30, der freundlich und aufgeschlossen lacht. Neben ihm eine Fachkraft Anfang 60. Alle drei haben ihren Platz auf dem KITA HUB gefunden und können die Vorteile nutzen. Sie sind miteinder digtial verbunden durch die Dienste und Angebote des KITA HUBs."
          />
        </div>
      </div>

      <div className={css["text"]}>
        <h1 className={css["text-1"]}>Kita. Bayern. Digital.</h1>

        <p className={css["text-2"]}>
          {" "}
          Ein kostenfreies staatliches Angebot für alle Kitas in Bayern. Mach
          mit und werde Teil einer großartigen bayernweiten Kita-Community.
        </p>

        <div className="d-flex justify-content-center">
          <button
            aria-label="Erfahre Mehr"
            onClick={() => setModalOpened(true)}
            className={css["button"]}
          >
            Mehr Infos
          </button>
        </div>
      </div>

      <Modal
        show={modalOpened}
        onClose={() => setModalOpened(false)}
        title="KITA HUB"
      >
        {/* <img src="/images/fernseher-retro.svg" /> */}

        <p>
          <video
            src="/video/erklaervideo-allgemein.mp4"
            controls
            poster="/video/poster-erklaervideo-allgemein.svg"
          ></video>
        </p>
        <p>
          Mit dem KITA HUB bauen das{" "}
          <a target="_blank" rel="noreferrer" href="https://www.ifp.bayern.de">
            Staatsinstitut für Frühpädagogik und Medienkompetenz (IFP)
          </a>{" "}
          und das{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stmas.bayern.de"
          >
            Bayerische Staatsministerium für Familie, Arbeit und Soziales
            (StMAS)
          </a>{" "}
          ein digitales Dienstleistungs- und Bildungsangebot für das
          frühpädagogische Arbeitsfeld in Bayern auf.
        </p>
        <p>
          Über diese Website gelangst du zu den verschiedenen Online-Diensten
          und Angeboten - alle sind kostenfrei und datenschutzkonform. Mithilfe
          der vielfältigen Services und Möglichkeiten kannst du dich fortbilden,
          in direkten Austausch mit anderen Kolleg:innen aus der Frühpädagogik
          kommen oder in der MEDIENECKE stöbern und so deinen Kita-Alltag
          bereichern.
        </p>
        <p>
          Einen Account erhalten derzeit Kita-Pädagog:innen, die an einer
          bayerischen Kita arbeiten, sowie Teilnehmende und Beteiligte von
          Projekten und Fortbildungsangeboten des{" "}
          <a target="_blank" rel="noreferrer" href="https://www.ifp.bayern.de">
            Staatsinstituts für Frühpädagogik und Medienkompetenz (IFP)
          </a>{" "}
          oder des{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.stmas.bayern.de"
          >
            Bayerischen Staatsministeriums für Familie, Arbeit und Soziales
            (StMAS)
          </a>{" "}
          .
        </p>
      </Modal>
    </div>
  );
};
