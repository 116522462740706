/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Modal } from "../../common/Modal/Modal";
import css from "./style.module.scss";

const chatLink = {
  target: "_blank",
  href: "https://www.chat.kita.bayern",
  rel: "noreferrer",
};

const meetingLink = {
  target: "_blank",
  href: "https://www.meeting.kita.bayern",
  rel: "noreferrer",
};

const kurseLink = {
  target: "_blank",
  href: "https://www.kurse.kita.bayern",
  rel: "noreferrer",
};

export const MiddleActionBar = () => {
  const [activeInfo, setActiveInfo] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveInfo((s) => {
        if (s === 2) {
          return 0;
        } else {
          return s + 1;
        }
      });

      return () => {
        clearInterval(interval);
      };
    }, 2000);
  }, []);

  const infoContents = {
    chat: {
      title: "Chat",
      body: (
        <div>
          <p>
            <video
              controls
              poster="/video/poster-erklaervideo-chat.svg"
              crossOrigin="anonymous"
            >
              <source
                src="https://static.kita.bayern/files/videos/tutorials/landing-page-chat.webm"
                type="video/webm"
              />
              <track
                src="https://static.kita.bayern/files/videos/tutorials/landing-page-chat.vtt"
                kind="subtitles"
                srcLang="de"
                label="Deutsch"
              />
            </video>
          </p>
          <p>
            Mit dem Programm &quot;Rocket.Chat&quot; hast du hier einen
            kostenfreien, datenschutzkonformen Messenger-Dienst zur Verfügung,
            der sich explizit an Kita-Mitarbeitende richtet. Du kannst einzelne
            Personen direkt anschreiben, oder auch in geschlossenen Kanälen mit
            mehreren Personen in der Gruppe kommunizieren. In öffentlichen
            Kanälen erhältst du Infos und Hilfestellung zu verschiedenen Themen.
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.chat.kita.bayern"
            >
              www.chat.kita.bayern
            </a>
          </p>
        </div>
      ),
    },
    meeting: {
      title: "Meeting",
      body: (
        <div>
          <p>
            <video
              controls
              poster="/video/poster-erklaervideo-meeting.svg"
              crossOrigin="anonymous"
            >
              <source
                src="https://static.kita.bayern/files/videos/tutorials/landing-page-meeting.webm"
                type="video/webm"
              />
              <track
                src="https://static.kita.bayern/files/videos/tutorials/landing-page-meeting.vtt"
                kind="subtitles"
                srcLang="de"
                label="Deutsch"
              />
            </video>
          </p>
          <p>
            Über das Programm &quot;BigBlueButton&quot; kannst du dir bis zu 10
            unterschiedliche Videokonferenzräume parallel anlegen und starten,
            wann immer du sie benötigst. Hinterlege die Einstellungen so, wie du
            sie brauchst und lade z.B. dein Team oder Eltern zu
            Online-Besprechungen oder virtuellen Veranstaltungen ein.
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.meeting.kita.bayern"
            >
              www.meeting.kita.bayern
            </a>
          </p>
        </div>
      ),
    },
    kurse: {
      title: "Kurse",
      body: (
        <div>
          <p>
            <video
              controls
              poster="/video/poster-erklaervideo-kurse.svg"
              crossOrigin="anonymous"
            >
              <source
                src="https://static.kita.bayern/files/videos/tutorials/landing-page-kurse.webm"
                type="video/webm"
              />
              <track
                src="https://static.kita.bayern/files/videos/tutorials/landing-page-kurse.vtt"
                kind="subtitles"
                srcLang="de"
                label="Deutsch"
              />
            </video>
          </p>
          <p>
            Neben Kursen, für die du dich z.B. im Rahmen von Kampagnen oder
            Fortbildungsprogrammen anmelden kannst, findest du hier auch
            kostenfreie, offen zugängliche Online-Kurse zu verschiedenen Themen.
          </p>
          <p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kurse.kita.bayern"
            >
              www.kurse.kita.bayern
            </a>
          </p>
        </div>
      ),
    },
  };

  const [modalContent, setModalContent] = useState<
    "chat" | "meeting" | "kurse" | null
  >(null);

  useEffect(() => {
    if (modalContent) {
      setTimeout(() => {
        (
          document.querySelector("#open-modal video") as HTMLVideoElement
        )?.play();
      }, 2000);
    }
  }, [modalContent]);

  return (
    <div
      className={classNames(
        "d-flex justify-content-evenly flex-wrap",
        css["action-column-grid"]
      )}
    >
      <Modal
        show={modalContent !== null}
        onClose={() => setModalContent(null)}
        title={modalContent ? infoContents[modalContent].title : ""}
      >
        {modalContent ? infoContents[modalContent].body : ""}
      </Modal>

      <div className={classNames(css["chat-column"])}>
        <a
          tabIndex={0}
          className={classNames(css["chat-icon-bubble"], css["bubble"])}
          {...chatLink}
        >
          <img src="/images/middle-action-bar/Chat_Textbutton_02.svg" alt="" />
          <div className={classNames(css["chat-bubble-text"])}>
            Vernetze <br /> dich mit anderen
          </div>
        </a>
        <img
          className={classNames(css["chat-icon-arrow"])}
          src="/images/middle-action-bar/Chat_Icon_Arrow.svg"
          alt=""
        />

        <img
          className={classNames(css["chat-icon"])}
          src="/images/middle-action-bar/Chat.svg"
          alt=""
        />
        <a {...chatLink}>
          <img
            className={classNames(css["bubble"])}
            src="/images/middle-action-bar/Chat_Textbutton_01.svg"
            alt=""
          />
        </a>
        <img
          tabIndex={0}
          className={classNames(css["chat-icon-info"], {
            [css.active]: true,
          })}
          onClick={() => setModalContent("chat")}
          src="/images/play-icon.svg"
          alt=""
        />

        <h2 className={classNames(css["chat-text"])}>Chat</h2>
      </div>

      <div className={classNames(css["chat-column"])}>
        <a
          tabIndex={0}
          className={classNames(css["meeting-icon-bubble"], css["bubble"])}
          {...meetingLink}
        >
          <img
            src="/images/middle-action-bar/Meeting_Textbutton_02.svg"
            alt=""
          />
          <div className={classNames(css["meeting-bubble-text"])}>
            Starte deine Video- Konferenz
          </div>
        </a>
        <img
          className={classNames(css["meeting-icon-arrow"])}
          src="/images/middle-action-bar/Meeting_Icon_Arrow.svg"
          alt=""
        />
        <img
          className={classNames(css["chat-icon"])}
          src="/images/middle-action-bar/Meeting.svg"
          alt=""
        />

        <a {...meetingLink}>
          <img
            className={classNames(css["bubble"])}
            src="/images/middle-action-bar/Meeting_Textbutton_01.svg"
            alt=""
          />
        </a>

        <img
          tabIndex={0}
          onClick={() => setModalContent("meeting")}
          className={classNames(css["meeting-icon-info"], {
            [css.active]: true,
          })}
          src="/images/play-icon.svg"
          alt=""
        />

        <h2 className={classNames(css["chat-text"])}>Meeting</h2>
      </div>

      <div>
        <div className={classNames(css["chat-column"])}>
          <a
            tabIndex={0}
            className={classNames(css["kurse-icon-bubble"], css["bubble"])}
            {...kurseLink}
          >
            <img
              src="/images/middle-action-bar/Kurse_Textbutton_02.svg"
              alt=""
            />
            <div className={classNames(css["kurse-bubble-text"])}>
              Mach mit und bilde dich weiter
            </div>
          </a>
          <img
            className={classNames(css["kurse-icon-arrow"])}
            src="/images/middle-action-bar/Kurse_Icon_Arrow.svg"
            alt=""
          />
          <img
            className={classNames(css["kurse-icon"])}
            src="/images/middle-action-bar/Kurse.svg"
            alt=""
          />

          <a {...kurseLink}>
            <img
              className={classNames(css["bubble"])}
              src="/images/middle-action-bar/Kurse_Textbutton_01.svg"
              alt=""
            />
          </a>

          <img
            tabIndex={0}
            onClick={() => setModalContent("kurse")}
            className={classNames(css["kurse-icon-info"], {
              [css.active]: true,
            })}
            src="/images/play-icon.svg"
            alt=""
          />

          <h2 className={classNames(css["chat-text"])}>Kurse</h2>
        </div>
      </div>
    </div>
  );
};
