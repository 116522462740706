import React from "react";
import css from "./style.module.scss";
import { NavBar } from "../../../components/NavBar/NavBar";
import { Footer } from "../../../components/Footer/Footer";
import classNames from "classnames";
import { CentralText } from "../../../components/kita-home/CentralText/CentralText";
import { MiddleActionBar } from "../../../components/kita-home/MiddleActionBar/MiddleActionBar";
import { BottonCardPanel } from "../../../components/kita-home/BottomCardPanel/BottomCardPanel";
import {
  IStreamConfig,
  StreamDisruptor,
} from "../../../components/common/StreamDisruptor/StreamDisruptor";
import { Domain } from "../../../types/common";

const KitaBayernHome = ({
  isDev,
  streamConfig,
  collagen,
}: {
  isDev: boolean;
  streamConfig: IStreamConfig;
  collagen: string[];
}) => {
  return (
    <div>
      <NavBar isDev={isDev} domain={Domain.KITAHUB} />

      <div
        className={classNames(css["main-layout"], "d-flex flex-column gap-5")}
      >
        <div className={css["central-text-panel"]}>
          <CentralText collagen={collagen} />
        </div>

        <div className={css["middle-action-panel"]}>
          <MiddleActionBar />
        </div>

        <div className={css["bottom-card-panel"]}>
          <BottonCardPanel isDev={isDev} />
        </div>
      </div>

      {/* <div className={css["chat-bot-icon"]}>
  <ChatBot />
</div> */}

      <div className={css["disruptor-wrapper"]}>
        {streamConfig.showDisruptor && (
          <StreamDisruptor
            title={streamConfig.title}
            subtitle={streamConfig.subtitle}
            disruptorStreamSource={streamConfig.disruptorStreamSource}
            streamStart={streamConfig.streamStart}
          />
        )}
      </div>

      <div>
        <Footer domain={Domain.KITAHUB} />
      </div>
    </div>
  );
};

export default KitaBayernHome;
